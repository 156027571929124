#root {
	min-width: 513px;
}

.default-page-size {
	min-height: 75vh;
	padding-bottom: 1rem;
}


/* FOOTER */
.footer-body {
	border-top: 1px dotted black;
	padding-top: 1rem;
	height: 8vh;
}

.footer-body a {
	color: #404040;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}

/* HOME AND COMMON */
/* PAINTINGS AND INTERIOR */
.home-text {
	font-size: 0.9rem;
	padding: 1rem 1rem;
}

@media screen and (max-width: 810px) {
	h1 {
		font-size: 2rem !important;
	}

	.privacy-policy-text {
		padding: 1rem;
		border-left: none;
	}
}

@media screen and (max-width: 652px) {
	body {
		min-width: 496px !important;
	}

	h1 {
		font-size: 1.8rem !important;
	}

	.privacy-policy-text {
		padding: 1rem;
		border-left: none;
	}
}

/* VITA */
@media screen and (min-width: 992px) {
	.vita-text {
		max-width: 24rem;
	}

	.privacy-policy-text {
		padding: 1rem;
		border-left: none;
	}
}


/* CONTACT AND PRIVACY POLICY */
.privacy-policy-text {
	font-size: 0.7rem;
	border-left: 3px solid black;
	padding-left: 2rem;
}

.privacy-policy-text span {
	font-weight: bold;
	line-height: 2.1rem;
}

/* NEWS AND EVENTS*/
.news {
	height: 20rem;
}

.news-entry {
	border-bottom: 16px solid black;
	margin-bottom: 4px;
	padding-bottom: 4px;
}

/*.news-entry img {
	max-width: 80%;
}*/

.news-entry p {
	margin-left: 2rem;
}


/* GALLERY */
@media screen and (min-width: 1379px) and (min-height: 919px) {
	.react-images__dialog .react-images__container .react-images__footer .react-images__footer__caption {
		font-size: 1.2rem;
		color: white;
		border-right: 3px solid #595959;
		padding-right: 1rem;
		position: absolute;
		top: -80vh;
		right: 10vh;
		max-width: 12rem;
	}

	.react-images__dialog .react-images__container .react-images__footer .react-images__footer__caption--isFullscreen {
		color: black;
	}

	.react-images__track .react-images__view--isFullscreen {
		background-color: white;
	}
}

.react-images__header.react-images__header--isModal.css-9xjzrb.css-1ycyyax>span:nth-child(2)>button.react-images__header_button.react-images__header_button--close.css-m6j0xf.css-1ycyyax,
body>div.fullscreen.fullscreen-enabled>div.react-images__positioner.react-images__positioner--isFullscreen.css-1rbq2qy.css-1ycyyax>div>div>main>nav>button.css-xfk84m.css-1ycyyax,
body>div.fullscreen.fullscreen-enabled>div.react-images__positioner.react-images__positioner--isFullscreen.css-1rbq2qy.css-1ycyyax>div>div>main>nav>button.css-1h82jk3.css-1ycyyax {
	background-color: grey;
}

#root>div.default-page-size.fade.show>div>div>div.col-lg-12>div>div>div>img:hover {
	border: 1px solid #e6e6e6;
	padding: 6px;
	transition: 0.2s ease-in-out all;
}