nav>ul>li a {
	color: black;
	padding-right: 1.9rem;
}

#root>div.container>div>div.col-lg-9>nav>ul>li.dropdown.nav-item>a {
	padding: 0rem 0rem;
	padding-right: 1.9rem;
}

div.dropdown-menu.show {
	max-width: 5rem;
	border-right: 1px solid grey;
}

#root.custom-navbar {
	max-width: 75%;
}

.custom-navbar img {
	max-width: 286px;
}

.nav-item a:hover {
	text-decoration: none;
	color: black;
	font-weight: bold;
}

#root>div:nth-child(1)>div>div.col-lg-9>nav>ul>li.dropdown.show.nav-item>a[aria-expanded="true"] {
	color: black !important;
	font-weight: bold;
}

.nav-item .selected {
	color: black;
	font-weight: bold;
	transition: 0.2s ease-in-out all;
	text-decoration: none;

}

#root>div.container>div>div.col-lg-9>nav>ul>li.dropdown.show.nav-item>div>button:hover {
	background-color: white;
}

@media screen and (max-width: 510px) {

	#navbar-col>nav>ul>li.dropdown.show.nav-item>div {
		margin: 0rem 0rem;
		width: 8rem;
	}
}

@media screen and (min-width: 494px) {

	#navbar-col>nav>ul>li.dropdown.show.nav-item>div {
		margin: 0rem 0rem;
		width: 6rem;
	}
}

@media screen and (max-width: 767px) {
	#navbar-col {
		width: 100% !important;
		text-align: right;
	}

	#navbar-col .navbar {
		width: 50% !important;
		margin-left: 49%;
	}

	#navbar-col>nav>ul>li.dropdown.show.nav-item>div {
		margin-left: 2.3rem;
		width: 12rem;
	}

	nav>ul>li a {
		color: black;
		padding-right: 0rem;
	}

	#root>div.container>div>div.col-lg-9>nav>ul>li.dropdown.nav-item>a {
		padding: 0rem 0rem;
	}

	#navbar-col>nav>ul>li.dropdown.show.nav-item>div>button>li {
		text-align: right;
		padding: 0rem 0rem;
		margin: 0rem 0rem;
	}

	#navbar-col>nav>ul>li.dropdown.show.nav-item>div>button>li>a {
		text-align: right;
		padding: 0rem 0rem;
		margin: 0rem 0rem;
	}


}